<template>
	<div class="text-gray-800">
		<ATDContainer class="text-gray-400">
			<div
				class="container w-full md:p-6 lg:p-12 bg-white border-8 border-gray-800 rounded-2xl"
			>
				<iframe
					data-tally-src="https://tally.so/embed/mVZEkv?alignLeft=1&transparentBackground=1&dynamicHeight=1"
					loading="lazy"
					width="100%"
					height="484"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
					title="Contact Us"
				></iframe>
			</div>
		</ATDContainer>
	</div>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	components: { ATDContainer }
};
</script>
<style scoped>
.spectra {
	width: 150vw;
	height: auto;
	margin-right: -100vw;
	margin-top: -5vw;
	/* opacity: 0.3; */
}
</style>
