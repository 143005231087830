<template>
	<div class="">
		<ATDContainer>
			<div class="p-12 line-18 bg-white rounded-2xl">
				<div class="text-3xl md:text-6xl text-black font-bold">
					Privacy
				</div>
				<div class="text-2xl md:text-2xl text-black font-bold pt-6">
					for all products owned by QuantumParadox Studio
				</div>
				<div class="font-semibold text-lg md:text-xl text-gray-500">
					<div class="py-2 text-lg pt-4">
						Last updated 13th March, 2021
					</div>
					<hr class="my-6" />
					<div class="py-2">
						<span>QuantumParadox Studio</span>
						(“we” or “us” or “our”) respects the privacy of our
						users (“user” or “you”). This Privacy Policy explains
						how we collect, use, disclose, and safeguard your
						information when you visit our mobile application (the
						“Application”).&nbsp;Please read this Privacy Policy
						carefully.
					</div>
					<div class="py-2">
						We reserve the right to make changes to this Privacy
						Policy at any time and for any reason. We will alert you
						about any changes by updating the “Last updated” date of
						this Privacy Policy. You are encouraged to periodically
						review this Privacy Policy to stay informed of updates.
						You will be deemed to have been made aware of, will be
						subject to, and will be deemed to have accepted the
						changes in any revised Privacy Policy by your continued
						use of the Application after the date such revised
						Privacy Policy is posted.
					</div>
					<div class="py-2">
						This Privacy Policy does not apply to the third-party
						online/mobile store from which you install the
						Application or make payments, including any in-game
						virtual items, which may also collect and use data about
						you. We are not responsible for any of the data
						collected by any such third party.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						COLLECTION OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We may collect information about you in a variety of
						ways. The information we may collect via the Application
						depends on the content and materials you use, and
						includes:
					</div>
					<div class="text-xl text-black py-6">Personal Data</div>
					<div class="py-2">
						Demographic and other personally identifiable
						information (such as your name and email address) that
						you voluntarily give to us when choosing to participate
						in various activities related to the Application, such
						as chat, posting messages in comment sections or in our
						forums, liking posts, sending feedback, and responding
						to surveys. If you choose to share data about yourself
						via your profile, online chat, or other interactive
						areas of the Application, please be advised that all
						data you disclose in these areas is public and your data
						will be accessible to anyone who accesses the
						Application.
					</div>
					<div class="text-xl text-black py-6">Derivative Data</div>
					<div class="py-2">
						Information our servers automatically collect when you
						access the Application, such as your native actions that
						are integral to the Application, including liking,
						re-blogging, or replying to a post, as well as other
						interactions with the Application and other users via
						server log files.
					</div>
					<div class="text-xl text-black py-6">Financial Data</div>
					<div class="py-2">
						Financial information, such as data related to your
						payment method (e.g. valid credit card number, card
						brand, expiration date) that we may collect when you
						purchase, order, return, exchange, or request
						information about our services from the Application. We
						store only very limited, if any, financial information
						that we collect. Otherwise, all financial information is
						stored by our payment processor, Paypal, and you are
						encouraged to review their privacy policy and contact
						them directly for responses to your questions.
					</div>
					<div class="text-xl text-black py-6">
						Facebook Permissions
					</div>
					<div class="py-2">
						The Application may by default access your
						<a href="https://www.facebook.com/about/privacy/"
							>Facebook</a
						>
						basic account information, including your name, email,
						gender, birthday, current city, and profile picture URL,
						as well as other information that you choose to make
						public. We may also request access to other permissions
						related to your account, such as friends, checkins, and
						likes, and you may choose to grant or deny us access to
						each individual permission. For more information
						regarding Facebook permissions, refer to the
						<a
							href="https://developers.facebook.com/docs/permissions/reference"
							>Facebook Permissions Reference</a
						>
						page.
					</div>
					<div class="text-xl text-black py-6">
						Geo-Location Information
					</div>
					<div class="py-2">
						We may request access or permission to and track
						location-based information from your mobile device,
						either continuously or while you are using the
						Application, to provide location-based services. If you
						wish to change our access or permissions, you may do so
						in your device’s settings.
					</div>
					<div class="text-xl text-black py-6">
						Mobile Device Access
					</div>
					<div class="py-2">
						We may request access or permission to certain features
						from your mobile device, including your mobile device’s
						Bluetooth, WiFi. If you wish to change our access or
						permissions, you may do so in your device’s settings.
					</div>
					<div class="text-xl text-black py-6">
						Mobile Device Data
					</div>
					<div class="py-2">
						Device information such as your mobile device ID number,
						model, and manufacturer, version of your operating
						system, phone number, country, location, and any other
						data you choose to provide.
					</div>
					<div class="text-xl text-black py-6">
						Push Notifications
					</div>
					<div class="py-2">
						We may request to send you push notifications regarding
						your account or the Application. If you wish to opt-out
						from receiving these types of communications, you may
						turn them off in your device’s settings.
					</div>
					<div class="text-xl text-black py-6">Third-Party Data</div>
					<div class="py-2">
						Information from third parties, such as personal
						information or network friends, if you connect your
						account to the third party and grant the Application
						permission to access this information.
					</div>
					<div>Data From Contests, Giveaways, and Surveys</div>
					<div class="py-2">
						Personal and other information you may provide when
						entering contests or giveaways and/or responding to
						surveys.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						USE OF YOUR INFORMATION
					</div>
					<div class="py-2">
						Having accurate information about you permits us to
						provide you with a smooth, efficient, and customized
						experience. Specifically, we may use information
						collected about you via the Application to:
					</div>
					<ol class="py-2">
						<li>
							Administer sweepstakes, promotions, and contests.
						</li>
						<li>Assist law enforcement and respond to subpoena.</li>
						<li>
							Compile anonymous statistical data and analysis for
							use internally or with third parties.
						</li>
						<li>Create and manage your account.</li>
						<li>
							Deliver targeted advertising, coupons, newsletters,
							and other information regarding promotions and the
							Application to you.
						</li>
						<li>Email you regarding your account or order.</li>
						<li>Enable user-to-user communications.</li>
						<li>
							Fulfill and manage purchases, orders, payments, and
							other transactions related to the Application.
						</li>
						<li>
							Generate a personal profile about you to make future
							visits to the Application more personalized.
						</li>
						<li>
							Increase the efficiency and operation of the
							Application.
						</li>
						<li>
							Monitor and analyze usage and trends to improve your
							experience with the Application.
						</li>
						<li>Notify you of updates to the Application.</li>
						<li>
							Offer new products, services, mobile applications,
							and/or recommendations to you.
						</li>
						<li>Perform other business activities as needed.</li>
						<li>
							Prevent fraudulent transactions, monitor against
							theft, and protect against criminal activity.
						</li>
						<li>Process payments and refunds.</li>
						<li>
							Request feedback and contact you about your use of
							the Application.
						</li>
						<li>Resolve disputes and troubleshoot problems.</li>
						<li>
							Respond to product and customer service requests.
						</li>
						<li>Send you a newsletter.</li>
						<li>Solicit support for the Application.</li>
					</ol>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						DISCLOSURE OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We may share information we have collected about you in
						certain situations. Your information may be disclosed as
						follows:
					</div>
					<div class="text-xl text-black py-6">
						By Law or to Protect Rights
					</div>
					<div class="py-2">
						If we believe the release of information about you is
						necessary to respond to legal process, to investigate or
						remedy potential violations of our policies, or to
						protect the rights, property, and safety of others, we
						may share your information as permitted or required by
						any applicable law, rule, or regulation. This includes
						exchanging information with other entities for fraud
						protection and credit risk reduction.
					</div>
					<div class="text-xl text-black py-6">
						Third-Party Service Providers
					</div>
					<div class="py-2">
						We may share your information with third parties that
						perform services for us or on our behalf, including
						payment processing, data analysis, email delivery,
						hosting services, customer service, and marketing
						assistance.
					</div>
					<div class="text-xl text-black py-6">
						Marketing Communications
					</div>
					<div class="py-2">
						With your consent, or with an opportunity for you to
						withdraw consent, we may share your information with
						third parties for marketing purposes, as permitted by
						law.
					</div>
					<div class="text-xl text-black py-6">
						Interactions with Other Users
					</div>
					<div class="py-2">
						If you interact with other users of the Application,
						those users may see your name, profile photo, and
						descriptions of your activity, including sending
						invitations to other users, chatting with other users,
						liking posts, following blogs.
					</div>
					<div class="text-xl text-black py-6">Online Postings</div>
					<div class="py-2">
						When you post comments, contributions or other content
						to the Applications, your posts may be viewed by all
						users and may be publicly distributed outside the
						Application in perpetuity
					</div>
					<div class="text-xl text-black py-6">
						Third-Party Advertisers
					</div>
					<div class="py-2">
						We may use third-party advertising companies to serve
						ads when you visit the Application. These companies may
						use information about your visits to the Application and
						other websites that are contained in web cookies in
						order to provide advertisements about goods and services
						of interest to you.
					</div>
					<div class="text-xl text-black py-6">Affiliates</div>
					<div class="py-2">
						We may share your information with our affiliates, in
						which case we will require those affiliates to honor
						this Privacy Policy. Affiliates include our parent
						company and any subsidiaries, joint venture partners or
						other companies that we control or that are under common
						control with us.
					</div>
					<div class="text-xl text-black py-6">Business Partners</div>
					<div class="py-2">
						We may share your information with our business partners
						to offer you certain products, services or promotions.
					</div>
					<div class="text-xl text-black py-6">Offer Wall</div>
					<div class="py-2">
						The Application may display a third-party-hosted “offer
						wall.” Such an offer wall allows third-party advertisers
						to offer virtual currency, gifts, or other items to
						users in return for acceptance and completion of an
						advertisement offer. Such an offer wall may appear in
						the Application and be displayed to you based on certain
						data, such as your geographic area or demographic
						information. When you click on an offer wall, you will
						leave the Application. A unique identifier, such as your
						user ID, will be shared with the offer wall provider in
						order to prevent fraud and properly credit your account.
					</div>
					<div class="text-xl text-black py-6">
						Social Media Contacts
					</div>
					<div class="py-2">
						If you connect to the Application through a social
						network, your contacts on the social network will see
						your name, profile photo, and descriptions of your
						activity.
					</div>
					<div class="text-xl text-black py-6">
						Other Third Parties
					</div>
					<div class="py-2">
						We may share your information with advertisers and
						investors for the purpose of conducting general business
						analysis. We may also share your information with such
						third parties for marketing purposes, as permitted by
						law.
					</div>
					<div class="text-xl text-black py-6">
						Sale or Bankruptcy
					</div>
					<div class="py-2">
						If we reorganize or sell all or a portion of our assets,
						undergo a merger, or are acquired by another entity, we
						may transfer your information to the successor entity.
						If we go out of business or enter bankruptcy, your
						information would be an asset transferred or acquired by
						a third party. You acknowledge that such transfers may
						occur and that the transferee may decline honor
						commitments we made in this Privacy Policy.
					</div>
					<div class="py-2">
						We are not responsible for the actions of third parties
						with whom you share personal or sensitive data, and we
						have no authority to manage or control third-party
						solicitations. If you no longer wish to receive
						correspondence, emails or other communications from
						third parties, you are responsible for contacting the
						third party directly.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						TRACKING TECHNOLOGIES
					</div>
					<div class="text-xl text-black py-6">
						Cookies and Web Beacons
					</div>
					<div class="py-2">
						We may use cookies, web beacons, tracking pixels, and
						other tracking technologies on the Application to help
						customize the Application and improve your experience.
						When you access the Application, your personal
						information is not collected through the use of tracking
						technology. Most browsers are set to accept cookies by
						default. You can remove or reject cookies, but be aware
						that such action could affect the availability and
						functionality of the Application. You may not decline
						web beacons. However, they can be rendered ineffective
						by declining all cookies or by modifying your web
						browser’s settings to notify you each time a cookie is
						tendered, permitting you to accept or decline cookies on
						an individual basis.
					</div>
					<div class="text-xl text-black py-6">Website Analytics</div>
					<div class="py-2">
						We may also partner with selected third-party vendors,
						such as Google Analytics to allow tracking technologies
						and remarketing services on the Application through the
						use of first party cookies and third-party cookies, to,
						among other things, analyze and track users’ use of the
						Application, determine the popularity of certain
						content, and better understand online activity. By
						accessing the Application, you consent to the collection
						and use of your information by these third-party
						vendors. You are encouraged to review their privacy
						policy and contact them directly for responses to your
						questions. We do not transfer personal information to
						these third-party vendors.
					</div>
					<div class="py-2">
						You should be aware that getting a new computer,
						installing a new browser, upgrading an existing browser,
						or erasing or otherwise altering your browser’s cookies
						files may also clear certain opt-out cookies, plug-ins,
						or settings.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						THIRD-PARTY WEBSITES
					</div>
					<div class="py-2">
						The Application may contain links to third-party
						websites and applications of interest, including
						advertisements and external services, that are not
						affiliated with us. Once you have used these links to
						leave the Application, any information you provide to
						these third parties is not covered by this Privacy
						Policy, and we cannot guarantee the safety and privacy
						of your information. Before visiting and providing any
						information to any third-party websites, you should
						inform yourself of the privacy policies and practices
						(if any) of the third party responsible for that
						website, and should take those steps necessary to, in
						your discretion, protect the privacy of your
						information. We are not responsible for the content or
						privacy and security practices and policies of any third
						parties, including other sites, services or applications
						that may be linked to or from the Application.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						SECURITY OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We use administrative, technical, and physical security
						measures to help protect your personal information.
						While we have taken reasonable steps to secure the
						personal information you provide to us, please be aware
						that despite our efforts, no security measures are
						perfect or impenetrable, and no method of data
						transmission can be guaranteed against any interception
						or other type of misuse. Any information disclosed
						online is vulnerable to interception and misuse by
						unauthorized parties. Therefore, we cannot guarantee
						complete security if you provide personal information.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						POLICY FOR CHILDREN
					</div>
					<div class="py-2">
						We do not knowingly solicit information from or market
						to children under the age of 13. If you become aware of
						any data we have collected from children under age 13,
						please contact us using the contact information provided
						below.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CONTROLS FOR DO-NOT-TRACK FEATURES
					</div>
					<div class="py-2">
						Most web browsers and some mobile operating systems
						include a Do-Not-Track (“DNT”) feature or setting you
						can activate to signal your privacy preference not to
						have data about your online browsing activities
						monitored and collected. No uniform technology standard
						for recognizing and implementing DNT signals has been
						finalized. As such, we do not currently respond to DNT
						browser signals or any other mechanism that
						automatically communicates your choice not to be tracked
						online. If a standard for online tracking is adopted
						that we must follow in the future, we will inform you
						about that practice in a revised version of this Privacy
						Policy.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						OPTIONS REGARDING YOUR INFORMATION
					</div>
					<div class="text-xl text-black py-6">
						Account Information
					</div>
					<div class="py-2">
						You may at any time review or change the information in
						your account or terminate your account by:&nbsp;
					</div>
					<ul class="py-2">
						<li>
							Logging into your account settings and updating your
							account
						</li>
						<li>
							Contacting us using the contact information provided
							below
						</li>
					</ul>
					<div class="py-2">
						Upon your request to terminate your account, we will
						deactivate or delete your account and information from
						our active databases. However, some information may be
						retained in our files to prevent fraud, troubleshoot
						problems, assist with any investigations, enforce our
						Terms of Use and/or comply with legal requirements.
					</div>
					<div class="text-xl text-black py-6">
						Emails and Communications
					</div>
					<div class="py-2">
						If you no longer wish to receive correspondence, emails,
						or other communications from us, you may opt-out
						by:&nbsp;
					</div>
					<ul class="py-2">
						<li>
							Noting your preferences at the time you register
							your account with the Application
						</li>
						<li>
							Logging into your account settings and updating your
							preferences.
						</li>
						<li>
							Contacting us using the contact information provided
							below
						</li>
					</ul>
					<div class="py-2">
						If you no longer wish to receive correspondence, emails,
						or other communications from third parties, you are
						responsible for contacting the third party directly.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CALIFORNIA PRIVACY RIGHTS
					</div>
					<div class="py-2">
						California Civil Code Section 1798.83, also known as the
						“Shine The Light” law, permits our users who are
						California residents to request and obtain from us, once
						a year and free of charge, information about categories
						of personal information (if any) we disclosed to third
						parties for direct marketing purposes and the names and
						addresses of all third parties with which we shared
						personal information in the immediately preceding
						calendar year. If you are a California resident and
						would like to make such a request, please submit your
						request in writing to us using the contact information
						provided below.
					</div>
					<div class="py-2">
						If you are under 18 years of age, reside in California,
						and have a registered account with the Application, you
						have the right to request removal of unwanted data that
						you publicly post on the Application. To request removal
						of such data, please contact us using the contact
						information provided below, and include the email
						address associated with your account and a statement
						that you reside in California. We will make sure the
						data is not publicly displayed on the Application, but
						please be aware that the data may not be completely or
						comprehensively removed from our systems.
					</div>
				</div>
			</div>
		</ATDContainer>
	</div>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	components: { ATDContainer }
};
</script>
