<template>
	<ATDContainer>
		<div class="mx-auto flex flex-row h-64 items-end justify-center">
			<div class="text-6xl font-bold text-white">Your Lost!</div>
		</div>
	</ATDContainer>
</template>

<script>
import ATDContainer from "../layouts/ATDContainer";

export default {
	components: { ATDContainer },
	data() {
		return {};
	}
};
</script>

<style scoped>
.support-form {
	width: 600px;
	height: 500px;
}

@media only screen and (max-width: 600px) {
	.support-form {
		width: 330px;
		height: 500px;
	}
}
</style>
