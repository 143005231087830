<template>
	<ATDContainer>
		<div
			class="container mx-auto flex flex-col items-center justify-center"
		>
			<div class="relative isolate">
				<div class="mx-auto w-full md:max-w-2xl pb-12">
					<div
						class="md:text-center flex flex-col md:justify-center md:items-center"
					>
						<h1
							class="font-bold tracking-tight text-5xl sm:text-6xl lg:text-8xl bg-gradient-to-r from-red-400 via-yellow-200 to-pink-400 text-transparent bg-clip-text"
							:style="{ 'line-height': '1.15em' }"
						>
							AdBlur
						</h1>
						<h2
							class="text-base uppercase tracking-wide font-semibold leading-7 text-red-400"
						>
							Your Browsing Companion
						</h2>
						<p
							class="mt-6 text-lg md:text-xl font-medium text-gray-500"
						>
							Seamlessly blur annoying ads surrounding your
							content. Concentrate on what matters most.
						</p>
						<div
							class="mt-10 flex flex-col md:flex-col md:items-center md:justify-center gap-2"
						>
							<a
								href="https://chromewebstore.google.com/detail/adblur/jlaeffogkckokegkabdgcebcckhnpabo"
								class="rounded-xl bg-red-600 px-8 py-4 text-base md:text-xl font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>Get the Chrome Extension</a
							>
							<div class="p-1">
								<img
									class="object-fill h-12"
									src="@/assets/image/avlbl-chrome-web-store.png"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="border border-gray-600 rounded-2xl mt-6">
				<video
					class="rounded-2xl"
					autoplay
					loop
					muted
					src="@/assets/videos/blur-toggle.mp4"
				/>
			</div>
			<!-- <div class="relative isolate px-6 pt-6 ">
				<div class="mx-auto w-full md:max-w-2xl pb-6 sm:pb-12 lg:pb-12">
					<div class="text-center">
						<h1
							class="font-bold tracking-tight text-5xl sm:text-7xl lg:text-8xl text-white"
							:style="{ 'line-height': '1.15em' }"
						>
							AdBlur
						</h1>
						<h2
							class="text-lg uppercase tracking-wide font-semibold leading-7 text-red-400"
						>
							Your Browsing Companion
						</h2>
						<p
							class="mt-6 text-lg md:text-xl font-medium text-gray-500"
						>
							Seamlessly blur annoying ads surrounding your
							content. Concentrate on what matters most.
						</p>
						<div
							class="mt-10 flex items-center justify-center gap-x-6"
						>
							<a
								v-if="false"
								href="#"
								class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>Text</a
							>
							<div class="p-1 bg-white bg-opacity-10 rounded-xl">
								<img
									class="object-fill w-64"
									src="@/assets/image/avlbl-chrome-web-store.png"
								/>
							</div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="border border-gray-600 rounded-2xl">
				<img
					class="object-fill p-2 w-full border-8 border-gray-900 rounded-2xl"
					src="@/assets/image/Screenshot-blur-settings.png"
				/>
			</div> -->
		</div>
		<div class="relative isolate py-24 lg:py-36">
			<div
				class="mx-auto pt-6 flex flex-col md:items-center md:justify-center gap-6"
			>
				<h2
					class="text-3xl md:text-3xl lg:text-6xl md:text-center font-bold uppercase bg-gradient-to-r from-red-700 via-yellow-400 to-pink-700 text-transparent bg-clip-text"
					:style="{ 'line-height': '1.2em' }"
				>
					Safeguard your sensitive browsing behaviour from
					<br />
					<div
						class="flex flex-row gap-4 items-center justify-center"
					>
						<span> prying eyes</span>
						<span>
							<img class="w-16" src="@/assets/image/eyes.svg" />
						</span>
					</div>
				</h2>
				<!-- <p
					class="mt-6 text-lg md:text-2xl font-semibold uppercase text-gray-600"
				>
					Experience a new level of browsing freedom with AdBlur.
				</p> -->
			</div>
			<div
				class="mx-auto mt-16 w-full md:max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl"
			>
				<dl
					class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-16"
				>
					<div v-for="feature in features" :key="feature.name">
						<dt
							class="text-2xl font-semibold leading-7 text-gray-200"
						>
							{{ feature.name }}
						</dt>
						<dd class="mt-4 text-lg leading-7 text-gray-600">
							{{ feature.description }}
						</dd>
					</div>
				</dl>
			</div>
		</div>
		<div class="p-6 xl:p-12 bg-white rounded-3xl mb-12">
			<iframe
				class="rounded-2xl border border-gray-200 shadow-md w-full"
				src="https://cards.producthunt.com/cards/posts/453278?v=1"
				width="500"
				height="800"
				frameborder="0"
				scrolling="no"
				allowfullscreen
			></iframe>
		</div>
		<div class="relative isolate py-18 sm:py-24 lg:py-36">
			<div
				class="mx-auto pt-6 flex flex-col md:items-center md:justify-center gap-2"
			>
				<h2
					class="text-3xl md:text-4xl lg:text-5xl md:text-center font-bold text-white"
					:style="{ 'line-height': '1.1em' }"
				>
					Multi-Browser<br />Multi-Device
					<span
						class="bg-gradient-to-r from-red-600 via-pink-600 to-yellow-300 text-transparent bg-clip-text"
						>License Key</span
					>
					<!-- Effortlessly integrate AdBlur into multiple Chrome browser
					profiles and devices for instant ad-masking capabilities. -->
				</h2>
				<h2
					class="text-base uppercase tracking-wide font-semibold md:text-center leading-7 text-red-400"
				>
					For less than a Dollar
				</h2>
				<h2
					class="text-7xl md:text-8xl lg:text-9xl md:text-center font-light tracking-tight text-white"
					:style="{ 'line-height': '1.2em' }"
				>
					<span class="text-gray-600">US</span>$ 0.99<span
						class="text-gray-600"
						>/mo</span
					>
					<!-- Effortlessly integrate AdBlur into multiple Chrome browser
					profiles and devices for instant ad-masking capabilities. -->
				</h2>
				<div
					class="mt-10 flex flex-col md:flex-row md:items-center md:justify-center gap-6"
				>
					<a
						href="https://chromewebstore.google.com/detail/adblur/jlaeffogkckokegkabdgcebcckhnpabo"
						class="rounded-xl bg-red-600 px-8 py-4 text-base md:text-xl font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>Get the Chrome Extension</a
					>
					<div class="p-1">
						<img
							class="object-fill h-16"
							src="@/assets/image/avlbl-chrome-web-store.png"
						/>
					</div>
				</div>
				<p
					class="w-full md:max-w-2xl mx-auto md:text-center py-6 text-lg md:text-xl font-medium text-gray-500"
				>
					Whether you're a professional seeking to maintain focus at
					work or an individual concerned about online privacy, AdBlur
					is your trusted companion.
				</p>
			</div>
		</div>
		<img
			class="object-fill w-full"
			src="@/assets/image/adblur-banner.png"
		/>

		<div class="relative isolate py-12">
			<div class="mx-auto p-6 md:p-12 xl:p-16 bg-gray-900 rounded-3xl">
				<h2
					class="text-xl md:text-2xl lg:text-3xl font-bold text-white"
					:style="{ 'line-height': '1.2em' }"
				>
					Disclaimer
				</h2>
				<ul
					class="w-full mx-auto py-6 text-lg md:text-xl font-medium text-gray-500"
				>
					<li>
						AdBlur doesn't show ads and doesn't send users' data
						anywhere.
					</li>
					<li>
						AdBlur doesn't use any analytics tool to track your
						browsing data.
					</li>
					<li>
						AdBlur is not created by, affiliated with, or supported
						by Incorporations shown in the Product Screenshots and
						Videos.
					</li>
					<li>
						All Websites shown in the video are for demonstration
						purpose only.
					</li>
				</ul>
			</div>
		</div>
	</ATDContainer>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	data() {
		return {
			features: [
				{
					name: "Enhanced Focus",
					description:
						"AdBlur intelligently detects and masks intrusive ads, ensuring a distraction-free reading experience. Say goodbye to skyscraper ads and drop banner ads that disrupt your workflow."
				},
				{
					name: "Privacy Protection",
					description:
						"AdBlur helps you maintain confidentiality, especially in shared environments like offices, co-working areas, family get-togethers or public spaces, by obscuring potentially revealing advertisements."
				},
				{
					name: "Screen Recording Companion",
					description:
						"Maintain professionalism during screen recordings at work with AdBlur. Ensure that your browsing habits remain private and avoid embarrassment by blurring out ads that might appear in your recordings."
				},
				{
					name: "Customisable Auto-Blur Settings",
					description:
						"Save Auto-Blur preferences for specific websites to fine-tune your browsing experience. Enable Auto-Blur All Tabs Setting before sharing your screen in public environments."
				}
				// {
				// 	name: "Affordable License",
				// 	description:
				// 		"Effortlessly integrate AdBlur into multiple Chrome browser profiles and devices for instant ad-masking capabilities."
				// }
			]
		};
	},
	components: { ATDContainer }
};
</script>
