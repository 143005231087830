<template>
	<div class="w-full z-50">
		<div class="container mx-auto px-12 p-12">
			<div
				class="flex flex-col md:flex-row md:justify-between items-start md:items-end"
			>
				<div>
					<a href="/" class="text-xl font-extrabold text-gray-200">
						QuantumParadox Studio
						<br class="block lg:hidden" />Private Limited
					</a>
					<div class="text-sm font-bold pt-1">
						<span class="">
							Corporate Identity Number (CIN)
							U72900WB2021PTC243998
						</span>
						<br /><br />
						<!-- We're at -->
						<a
							class="text-gray-400"
							href="mailto:photon@quantumparadox.studio"
						>
							photon@quantumparadox.studio
						</a>
						<span class="hidden md:inline">&#124;</span>
						Kolkata, India 🇮🇳
					</div>
				</div>
				<div v-if="show_qp_logo" class="text-sm font-bold mt-6">
					<a href="/privacy"> Privacy Policy </a>
					&nbsp; &centerdot; &nbsp;
					<a href="/terms"> Terms </a>
				</div>
				<div v-else class="text-sm font-bold mt-6">
					<a href="/adblur/privacy"> Privacy Policy </a>
					&nbsp; &centerdot; &nbsp;
					<a href="/adblur/terms"> Terms </a>
				</div>
			</div>
			<div
				class="w-full bg-gray-800 bg-opacity-60 my-6"
				style="height: 2px"
			></div>
			<div class="text-sm text-center font-semibold text-gray-600 pb-6">
				&copy;
				<a href="/">QuantumParadox Studio</a>
				2021 &nbsp; &centerdot; &nbsp; All Rights Reserved
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			show_qp_logo: false
		};
	},
	mounted() {
		console.log(window.location.pathname);
		if (window.location.pathname.indexOf("adblur") == -1) {
			this.show_qp_logo = true;
		}
	}
};
</script>
