<template>
	<div class="w-full relative py-6 xl:py-12">
		<div
			class="container mx-auto flex flex-row gap-12 justify-between items-center px-6 md:px-12"
		>
			<div class="w-max">
				<div
					v-if="show_qp_logo"
					class="p-2 border border-gray-800 rounded-2xl"
				>
					<a href="/">
						<img
							class="w-20"
							src="@/assets/image/QuantumParadox-Studio-Logo/SVG/QuantumParadox-Studio-Logo-2.svg"
						/>
					</a>
				</div>
				<div v-else>
					<a href="/adblur">
						<img
							class="w-20"
							src="@/assets/image/adblur-icon-small.png"
						/>
					</a>
				</div>
			</div>
			<div class="flex flex-row items-center justify-end gap-6">
				<a
					v-if="!show_qp_logo"
					href="https://www.producthunt.com/posts/adblur-hide-ads-focus-on-browsing?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-adblur&#0045;hide&#0045;ads&#0045;focus&#0045;on&#0045;browsing"
					target="_blank"
					><img
						src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=453278&theme=light"
						alt="AdBlur&#0032;&#0045;&#0032;Hide&#0032;Ads&#0044;&#0032;Focus&#0032;on&#0032;Browsing - Protect&#0032;your&#0032;Privacy&#0032;in&#0032;Public&#0032;and&#0032;during&#0032;screen&#0032;recordings | Product Hunt"
						style="width: 200px; height: 48px"
						width="200"
						height="48"
				/></a>
				<a
					href="/contact"
					target="_blank"
					class="text-white hover:text-white font-bold tracking-wide uppercase hover:underline"
					>Contact Us</a
				>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			show_qp_logo: false
		};
	},
	mounted() {
		console.log(window.location.pathname);
		if (window.location.pathname.indexOf("adblur") == -1) {
			this.show_qp_logo = true;
		}
	}
};
</script>
