<template>
	<div class="text-gray-800">
		<ATDContainer class="text-gray-400">
			<!-- Real Art is the agency of technology and design. We blend art and invention to create NEVER-BEFORE-SEEN experiences that launch brands and products on a world stage. -->
			<div class="w-full font-bold">
				<div
					class="mb-6 text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl t-i font-semibold font-mic tracking-wider"
				>
					We work on propelling your
					<span class="t-i-h">Experience</span> to
				</div>
				<div
					class="text-3xl text-white md:text-6xl xl:text-7xl relative w-full"
				>
					<div class="t-shb font-ak-sb t-y absolute z-50">
						Different<br />Dimensions
					</div>
					<!-- <div class="t-sh text-gray-900 absolute z-10 -top-2">
            Different<br />Dimensions
          </div> -->
					<!-- 
          <div class="t-p absolute z-20 top-3 -left-2">
            Different<br />Dimensions
          </div> -->
					<div
						v-for="(item, i) in grad"
						:class="'hidden lg:block absolute bg-black font-ak-o'"
						:style="'top:' + item.xl + 'px;'"
					>
						Dimensions
					</div>
					<div
						v-for="(item, i) in grad"
						:class="'hidden md:block lg:hidden bg-black absolute font-ak-o '"
						:style="'top:' + item.md + 'px;'"
					>
						Dimensions
					</div>
					<div
						v-for="(item, i) in grad"
						:class="'md:hidden absolute bg-black font-ak-o'"
						:style="'top:' + item.sm + 'px;'"
					>
						Dimensions
					</div>
					<!-- <div class="text-black absolute z-20 -top-2">
            Different<br />Dimensions
          </div> -->
				</div>
			</div>
		</ATDContainer>
		<div class="h-48 md:h-80 lg:h-96 lg:m-12"></div>
		<ATDContainer>
			<div>
				<div
					class="my-24 text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-green-400 font-semibold"
					style="font-family: Michroma"
				>
					What are we working on now?
				</div>
			</div>
			<div class="grid grid-cols-1">
				<template
					v-for="(subsidiary, index) in subsidiaries"
					:key="index"
				>
					<a
						:href="subsidiary.link"
						target="_blank"
						class="cursor-pointer select-none group transform ease-in-out scale-100 hover:scale-105"
						style="transition: all 0.3s ease-in-out"
					>
						<div
							class="bg-gray-900 bg-opacity-0 group-hover:bg-opacity-50 my-12 rounded-2xl"
						>
							<div class="flex flex-col lg:flex-row">
								<div class="p-6 lg:p-12">
									<div
										class="w-1/2 md:w-1/3 lg:w-48 xl:w-64 h-1/2 md:h-1/3 lg:h-48 xl:h-64 flex flex-col items-center justify-center rounded-2xl"
										:style="{
											backgroundColor:
												subsidiary.background
										}"
									>
										<div :class="subsidiary.class">
											<img
												class="rounded-2xl"
												:src="subsidiary.image"
											/>
										</div>
									</div>
								</div>
								<div class="p-6 lg:p-12">
									<div
										class="text-white text-3xl md:text-4xl font-bold"
									>
										{{ subsidiary.name }}
									</div>
									<div
										class="font-medium line-14 text-xl text-gray-700 my-6"
									>
										{{ subsidiary.subtitle }}
									</div>
									<div
										class="font-medium line-14 text-lg text-gray-400 my-6"
									>
										{{ subsidiary.about }}
									</div>
								</div>
							</div>

							<div
								class="flex flex-wrap text-xs gap-2 font-semibold font-mono px-6 pb-6"
							>
								<template
									v-for="(tag, ti) in subsidiary.tags"
									:key="'tag_' + ti"
								>
									<div
										class="px-2 py-1 bg-gray-900 text-green-400 rounded-md"
									>
										{{ tag }}
									</div>
								</template>
							</div>
						</div>
					</a>
				</template>
			</div>
		</ATDContainer>
	</div>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	components: { ATDContainer },
	data() {
		return {
			grad: [
				{
					xl: "570",
					md: "440",
					sm: "319"
				},
				{
					xl: "550",
					md: "420",
					sm: "304"
				},
				{
					xl: "530",
					md: "400",
					sm: "289"
				},
				{
					xl: "510",
					md: "375",
					sm: "271"
				},
				{
					xl: "480",
					md: "350",
					sm: "253"
				},
				{
					xl: "450",
					md: "325",
					sm: "235"
				},
				{
					xl: "420",
					md: "295",
					sm: "215"
				},
				{
					xl: "380",
					md: "265",
					sm: "195"
				},
				{
					xl: "340",
					md: "235",
					sm: "175"
				},
				{
					xl: "300",
					md: "200",
					sm: "150"
				},
				{
					xl: "250",
					md: "165",
					sm: "125"
				},
				{
					xl: "200",
					md: "130",
					sm: "100"
				},
				{
					xl: "150",
					md: "95",
					sm: "75"
				},
				{
					xl: "100",
					md: "60",
					sm: "50"
				}
			]
		};
	},
	computed: {
		subsidiaries: () => {
			return [
				{
					image: require("@/assets/image/apptodesign-transparent-logo.svg"),
					background: "#16168f",
					name: "AppToDesign",
					class: "p-6",
					subtitle: "Software Design Agency",
					link: "https://apptodesign.com",
					about: "A Bootstrapped Software Product Design Company focused on engineering fintech and business products which enable business owners to grow and scale their businesses beyond local borders.",
					tags: [
						"Payment Services",
						"Banking Tools",
						"SaaS Stack",
						"Fintech Platforms",
						"Productivity Tools",
						"Business Tools",
						"Blockchain Exchanges",
						"P2P Escrow Stack",
						"Marketing Websites in Jamstack",
						"Mobile App for Hobbyists"
					]
				},
				{
					image: require("@/assets/image/adblur-icon-small.png"),
					background: "#000000",
					name: "AdBlur",
					class: "shadow-lg rounded-2xl",
					subtitle:
						"Hide Ad. Turn On Your Focus Mode! Chrome Extension",
					link: "/adblur",
					about: "Introducing AdBlur, your ultimate focus companion for browsing. This Chrome Extension seamlessly blurs distracting ads, allowing you to immerse yourself in content without interruptions. Safeguard your privacy in office or public spaces while enjoying seamless reading experiences. Enhance productivity and concentration with AdBlur, your go-to tool for distraction-free browsing.",
					tags: [
						"Payment Services",
						"Banking Tools",
						"SaaS Stack",
						"Fintech Platforms",
						"Productivity Tools",
						"Business Tools",
						"Blockchain Exchanges",
						"P2P Escrow Stack",
						"Marketing Websites in Jamstack",
						"Mobile App for Hobbyists"
					]
				},
				{
					image: require("@/assets/image/notionpdf.png"),
					background: "#000000",
					name: "NotionPDF",
					class: "rounded-2xl",
					subtitle: "PDF Generator for Notion.so",
					link: "https://notionpdf.com",
					about: "A PDF Generator Tool for Notion.so users. This tool has a variety of PDF formatting options, branding, watermark and analytics, to enable Notion.so users manage PDF file functions for their Businesses.",
					tags: [
						"Business",
						"PDF",
						"No-Code",
						"Notion.so Official APIs",
						"Document Formatting",
						"Branding",
						"Analytics",
						"Puppeteer",
						"Directus",
						"Postgres",
						"VueJS",
						"Mailgun",
						"Stripe Payments",
						"Subscription Management"
					]
				},

				{
					image: require("@/assets/image/omnibuz.png"),
					background: "#000000",
					name: "Omnibuz",
					class: "",
					subtitle: "Shopify Mobile App Stack",
					link: "https://omnibuz.com",
					about: "A robust, scalable, high performance Shopify Mobile App Stack backed by Shopify Admin APIs and Firebase. The Omnibuz Shopify Stack provides next-gen customer engagement marketing functions from their Shopify Owner Dashboard.",
					tags: [
						"Shopify",
						"Android App",
						"iOS App",
						"Personalised Customer Engagement",
						"Live Shopping",
						"Firebase",
						"Flutter App Development",
						"Automated Push Notifications",
						"Customised Payment Gateway",
						"Shopify Payments"
					]
				},
				{
					image: require("@/assets/image/detox-art-name-light-1-300x99.png"),
					background: "#ffffff",
					name: "DetoxArt",
					class: "p-6",
					subtitle:
						"An Online Store for Posters, Mugs, and Tote Bags",
					link: "https://detoxart.in/",
					about: "Collection of Posters, Canvas, Mugs and Tote Bags portraying emotions with Geometric Abstractions for the Daily Makers, Hustlers and Doers. All Products are printed with certified Non-toxic Acrylic Ink.",
					tags: [
						"Merchandise",
						"Google Shopping",
						"Geometric Design",
						"Abstracts Collections",
						"Online Store",
						"Creator Commerce",
						"Instagram Marketing"
					]
				}
			];
		}
	}
};
</script>
<style scoped>
.spectra {
	width: 150vw;
	height: auto;
	margin-right: -100vw;
	margin-top: -5vw;
	/* opacity: 0.3; */
}
</style>
