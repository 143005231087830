<template>
	<div class="">
		<ATDContainer>
			<div class="p-12 line-18 bg-white rounded-2xl">
				<div class="text-3xl md:text-6xl text-black font-bold">
					Privacy
				</div>
				<div class="text-2xl md:text-2xl text-black font-bold pt-6">
					for AdBlur Chrome Extension owned by QuantumParadox Studio
				</div>
				<div class="font-semibold text-lg md:text-xl text-gray-500">
					<div class="py-2 text-lg pt-4">
						Last updated 16th April, 2024
					</div>
					<hr class="my-6" />
					<div class="py-2">
						<span>QuantumParadox Studio</span>
						(“we” or “us” or “our”) respects the privacy of our
						users (“user” or “you”). This Privacy Policy explains
						how we collect, use, disclose, and safeguard your
						information when you visit our mobile application (the
						“Application”).&nbsp;Please read this Privacy Policy
						carefully.
					</div>
					<div class="py-2">
						We reserve the right to make changes to this Privacy
						Policy at any time and for any reason. We will alert you
						about any changes by updating the “Last updated” date of
						this Privacy Policy. You are encouraged to periodically
						review this Privacy Policy to stay informed of updates.
						You will be deemed to have been made aware of, will be
						subject to, and will be deemed to have accepted the
						changes in any revised Privacy Policy by your continued
						use of the Application after the date such revised
						Privacy Policy is posted.
					</div>
					<div class="py-2">
						This Privacy Policy does not apply to the third-party
						online/mobile store from which you install the
						Application or make payments, including any in-game
						virtual items, which may also collect and use data about
						you. We are not responsible for any of the data
						collected by any such third party.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						COLLECTION OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We may collect information about you in a variety of
						ways. The information we may collect via the Application
						depends on the content and materials you use, and
						includes:
					</div>
					<div class="text-xl text-black py-6">Personal Data</div>
					<div class="py-2">
						Demographic and other personally identifiable
						information (such as your name and email address) that
						you voluntarily give to us when choosing to participate
						in various activities related to the Application, such
						as email. If you choose to share your email with our
						Subscription Manager Stripe, or other interactive areas
						of the Application, please be advised that all data you
						disclose to Stripe is managed by Stripe's Privacy
						Agreement.
					</div>
					<div class="text-xl text-black py-6">Derivative Data</div>
					<div class="py-2">
						Information our servers automatically collect when you
						access the Application are: Auto Blur Settings for
						Websites, selected and enabled by the user.
					</div>
					<div class="text-xl text-black py-6">Financial Data</div>
					<div class="py-2">
						We store only very limited, if any, financial
						information that we collect. Otherwise, all financial
						information is stored by our payment processor, Stripe,
						and you are encouraged to review their privacy policy
						and contact them directly for responses to your
						questions.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						USE OF YOUR INFORMATION
					</div>
					<div class="py-2">
						Having accurate information about you permits us to
						provide you with a smooth, efficient, and customized
						experience. Specifically, we may use information
						collected about you via the Application to:
					</div>
					<ol class="py-2">
						<li>Assist law enforcement and respond to subpoena.</li>
						<li>Create and manage your account.</li>
						<li>
							Deliver information regarding updates & promotions
							regarding the Application to you.
						</li>
						<li>Email you regarding your account or order.</li>
						<li>
							Fulfill and manage purchases, orders, payments, and
							other transactions related to the Application.
						</li>
						<li>
							Generate a personal profile about you to make future
							visits to the Application more personalized.
						</li>
						<li>
							Increase the efficiency and operation of the
							Application.
						</li>
						<li>Notify you of updates to the Application.</li>
						<li>Perform other business activities as needed.</li>
						<li>
							Prevent fraudulent transactions, monitor against
							theft, and protect against criminal activity.
						</li>
						<li>Process payments and refunds.</li>
						<li>
							Request feedback and contact you about your use of
							the Application.
						</li>
						<li>Resolve disputes and troubleshoot problems.</li>
						<li>
							Respond to product and customer service requests.
						</li>
						<li>Send you a newsletter.</li>
						<li>Solicit support for the Application.</li>
					</ol>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						DISCLOSURE OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We may share information we have collected about you in
						certain situations. Your information may be disclosed as
						follows:
					</div>
					<div class="text-xl text-black py-6">
						By Law or to Protect Rights
					</div>
					<div class="py-2">
						If we believe the release of information about you is
						necessary to respond to legal process, to investigate or
						remedy potential violations of our policies, or to
						protect the rights, property, and safety of others, we
						may share your information as permitted or required by
						any applicable law, rule, or regulation. This includes
						exchanging information with other entities for fraud
						protection and credit risk reduction.
					</div>
					<div class="text-xl text-black py-6">
						Third-Party Service Providers
					</div>
					<div class="py-2">
						We may share your information with third parties that
						perform services for us or on our behalf, including
						payment processing, data analysis, email delivery,
						hosting services, customer service, and marketing
						assistance.
					</div>
					<div class="text-xl text-black py-6">
						Marketing Communications
					</div>
					<div class="py-2">
						With your consent, or with an opportunity for you to
						withdraw consent, we may share your information with
						third parties for marketing purposes, as permitted by
						law.
					</div>
					<div class="text-xl text-black py-6">
						Interactions with Other Users
					</div>
					<div class="py-2">
						If you interact with other users of the Application,
						those users may see your name, profile photo, and
						descriptions of your activity, including sending
						invitations to other users, chatting with other users,
						liking posts, following blogs.
					</div>
					<div class="text-xl text-black py-6">Online Postings</div>
					<div class="py-2">
						When you post comments, contributions or other content
						to the Applications, your posts may be viewed by all
						users and may be publicly distributed outside the
						Application in perpetuity
					</div>
					<div class="text-xl text-black py-6">
						Third-Party Advertisers
					</div>
					<div class="py-2">
						We DO NOT use any third-party advertising companies to
						serve ads when you visit the Application.
					</div>
					<div class="text-xl text-black py-6">Business Partners</div>
					<div class="py-2">
						We may share your information with our business partners
						to offer you Subscription Management Services.
					</div>
					<div class="text-xl text-black py-6">
						Sale or Bankruptcy
					</div>
					<div class="py-2">
						If we reorganize or sell all or a portion of our assets,
						undergo a merger, or are acquired by another entity, we
						may transfer your information to the successor entity.
						If we go out of business or enter bankruptcy, your
						information would be an asset transferred or acquired by
						a third party. You acknowledge that such transfers may
						occur and that the transferee may decline honor
						commitments we made in this Privacy Policy.
					</div>
					<div class="py-2">
						We are not responsible for the actions of third parties
						with whom you share personal or sensitive data, and we
						have no authority to manage or control third-party
						solicitations. If you no longer wish to receive
						correspondence, emails or other communications from
						third parties, you are responsible for contacting the
						third party directly.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						TRACKING TECHNOLOGIES
					</div>
					<div class="text-xl text-black py-6">
						Cookies and Web Beacons
					</div>
					<div class="py-2">
						We DO NOT use cookies, web beacons, tracking pixels, and
						other tracking technologies on the Application to help
						customize the Application and improve your experience.
					</div>
					<div class="text-xl text-black py-6">Website Analytics</div>
					<div class="py-2">
						We DO NOT partner with selected third-party vendors,
						such as Google Analytics to allow tracking technologies
						and remarketing services on the Application through the
						use of first party cookies and third-party cookies, to,
						among other things, analyze and track users’ use of the
						Application, determine the popularity of certain
						content, and better understand online activity.
					</div>
					<div class="py-2">
						You should be aware that getting a new computer,
						installing a new browser, upgrading an existing browser,
						or erasing or otherwise altering your browser’s cookies
						files may also clear certain opt-out cookies, plug-ins,
						or settings.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						SECURITY OF YOUR INFORMATION
					</div>
					<div class="py-2">
						We use administrative, technical, and physical security
						measures to help protect your personal information.
						While we have taken reasonable steps to secure the
						personal information you provide to us, please be aware
						that despite our efforts, no security measures are
						perfect or impenetrable, and no method of data
						transmission can be guaranteed against any interception
						or other type of misuse. Any information disclosed
						online is vulnerable to interception and misuse by
						unauthorized parties. Therefore, we cannot guarantee
						complete security if you provide personal information.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						POLICY FOR CHILDREN
					</div>
					<div class="py-2">
						We do not knowingly solicit information from or market
						to children under the age of 13. If you become aware of
						any data we have collected from children under age 13,
						please contact us using the contact information provided
						below.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						OPTIONS REGARDING YOUR INFORMATION
					</div>
					<div class="text-xl text-black py-6">
						Account Information
					</div>
					<div class="py-2">
						You may at any time review or change the information in
						your account or terminate your account by:&nbsp;
					</div>
					<ul class="py-2">
						<li>
							Logging into your account settings and updating your
							account
						</li>
						<li>
							Contacting us using the contact information provided
							below
						</li>
					</ul>
					<div class="py-2">
						Upon your request to terminate your account, we will
						deactivate or delete your account and information from
						our active databases. However, some information may be
						retained in our files to prevent fraud, troubleshoot
						problems, assist with any investigations, enforce our
						Terms of Use and/or comply with legal requirements.
					</div>
					<div class="text-xl text-black py-6">
						Emails and Communications
					</div>
					<div class="py-2">
						If you no longer wish to receive correspondence, emails,
						or other communications from us, you may opt-out
						by:&nbsp;
					</div>
					<ul class="py-2">
						<li>
							Noting your preferences at the time you register
							your account with the Application
						</li>
						<li>
							Logging into your account settings and updating your
							preferences.
						</li>
						<li>
							Contacting us using the contact information provided
							below
						</li>
					</ul>
					<div class="py-2">
						If you no longer wish to receive correspondence, emails,
						or other communications from third parties, you are
						responsible for contacting the third party directly.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CALIFORNIA PRIVACY RIGHTS
					</div>
					<div class="py-2">
						California Civil Code Section 1798.83, also known as the
						“Shine The Light” law, permits our users who are
						California residents to request and obtain from us, once
						a year and free of charge, information about categories
						of personal information (if any) we disclosed to third
						parties for direct marketing purposes and the names and
						addresses of all third parties with which we shared
						personal information in the immediately preceding
						calendar year. If you are a California resident and
						would like to make such a request, please submit your
						request in writing to us using the contact information
						provided below.
					</div>
					<div class="py-2">
						If you are under 18 years of age, reside in California,
						and have a registered account with the Application, you
						have the right to request removal of unwanted data that
						you publicly post on the Application. To request removal
						of such data, please contact us using the contact
						information provided below, and include the email
						address associated with your account and a statement
						that you reside in California. We will make sure the
						data is not publicly displayed on the Application, but
						please be aware that the data may not be completely or
						comprehensively removed from our systems.
					</div>
				</div>
			</div>
		</ATDContainer>
	</div>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	components: { ATDContainer }
};
</script>
