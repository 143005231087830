import { createRouter, createWebHistory } from "vue-router";
import Page404 from "../views/404.vue";
import Contact from "../views/Contact.vue";
import Home from "../views/Home.vue";
import AdblurLanding from "../views/adblur/Landing.vue";
import AdblurPrivacy from "../views/adblur/Privacy.vue";
import AdblurTerms from "../views/adblur/Terms.vue";
import PrivacyPolicy from "../views/legal/PrivacyPolicy.vue";
import Terms from "../views/legal/Terms.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact
	},
	{
		path: "/privacy",
		name: "PrivacyPolicy",
		component: PrivacyPolicy
	},
	{
		path: "/terms",
		name: "Terms",
		component: Terms
	},
	{
		path: "/adblur",
		name: "AdblurLanding",
		component: AdblurLanding
	},
	{
		path: "/adblur/privacy",
		name: "AdblurPrivacy",
		component: AdblurPrivacy
	},
	{
		path: "/adblur/terms",
		name: "AdblurTerms",
		component: AdblurTerms
	},
	{
		path: "/:pathMatch(.*)*",
		name: "Page404",
		component: Page404,
		props: true
	}
];

const router = createRouter({
	mode: "history",
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
