<template>
	<div class="">
		<ATDContainer>
			<div class="p-12 line-18 bg-white rounded-2xl">
				<div class="text-3xl md:text-6xl text-black font-bold">
					Terms
				</div>
				<div class="text-2xl md:text-2xl text-black font-bold pt-6">
					for AdBlur Chrome Extension owned by QuantumParadox Studio
				</div>
				<div class="font-semibold text-lg md:text-xl text-gray-500">
					<div class="py-2 text-lg pt-4">
						Last updated 16th April, 2024
					</div>
					<hr class="my-6" />
					<div class="text-xl md:text-3xl text-black font-bold pb-6">
						AGREEMENT TO TERMS
					</div>
					<div class="py-2">
						These Terms and Conditions constitute a legally binding
						agreement made between you, whether personally or on
						behalf of an entity (“you”) and QuantumParadox Studio
						(“we,” “us” or “our”), concerning your access to and use
						of our mobile application&nbsp;(the “Application”). You
						agree that by accessing the Application, you have read,
						understood, and agree to be bound by all of these Terms
						and Conditions Use. IF YOU DO NOT AGREE WITH ALL OF
						THESE TERMS AND CONDITIONS, THEN YOU ARE EXPRESSLY
						PROHIBITED FROM USING THE APPLICATION AND YOU MUST
						DISCONTINUE USE IMMEDIATELY.
					</div>
					<div class="py-2">
						Supplemental terms and conditions or documents that may
						be posted on the Application from time to time are
						hereby expressly incorporated herein by reference. We
						reserve the right, in our sole discretion, to make
						changes or modifications to these Terms and Conditions
						at any time and for any reason. We will alert you about
						any changes by updating the “Last updated” date of these
						Terms and Conditions and you waive any right to receive
						specific notice of each such change. It is your
						responsibility to periodically review these Terms and
						Conditions to stay informed of updates. You will be
						subject to, and will be deemed to have been made aware
						of and to have accepted, the changes in any revised
						Terms and Conditions by your continued use of the
						Application after the date such revised Terms are
						posted.
					</div>
					<div class="py-2">
						The information provided on the Application is not
						intended for distribution to or use by any person or
						entity in any jurisdiction or country where such
						distribution or use would be contrary to law or
						regulation or which would subject us to any registration
						requirement within such jurisdiction or country.
						Accordingly, those persons who choose to access the
						Application from other locations do so on their own
						initiative and are solely responsible for compliance
						with local laws, if and to the extent local laws are
						applicable.
					</div>
					<div class="py-2">
						The Application is intended for users who are at least
						13 years of age. All users who are minors in the
						jurisdiction in which they reside (generally under the
						age of 18) must have the permission of, and be directly
						supervised by, their parent or guardian to use the
						Application. If you are a minor, you must have your
						parent or guardian read and agree to these Terms of Use
						prior to you using the Application.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						INTELLECTUAL PROPERTY RIGHTS
					</div>
					<div class="py-2">
						Unless otherwise indicated, the Application is our
						proprietary property and all source code, databases,
						functionality, software, website designs, audio, video,
						text, photographs, and graphics on the Application
						(collectively, the “Content”) and the trademarks,
						service marks, and logos contained therein (the “Marks”)
						are owned or controlled by us or licensed to us, and are
						protected by copyright and trademark laws and various
						other intellectual property rights and unfair
						competition laws of the United States, foreign
						jurisdictions, and international conventions. The
						Content and the Marks are provided on the Application
						“AS IS” for your information and personal use only.
						Except as expressly provided in these Terms of Use, no
						part of the Application and no Content or Marks may be
						copied, reproduced, aggregated, republished, uploaded,
						posted, publicly displayed, encoded, translated,
						transmitted, distributed, sold, licensed, or otherwise
						exploited for any commercial purpose whatsoever, without
						our express prior written permission.
					</div>
					<div class="py-2">
						Provided that you are eligible to use the Application,
						you are granted a limited license to access and use the
						Application and to download or print a copy of any
						portion of the Content to which you have properly gained
						access solely for your personal, non-commercial use. We
						reserve all rights not expressly granted to you in and
						to the Application, Content, and the Marks.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						USER REPRESENTATIONS
					</div>
					<div class="py-2">
						By using the Application, you represent and warrant
						that: (1) all registration information you submit will
						be true, accurate, current, and complete; (2) you will
						maintain the accuracy of such information and promptly
						update such registration information as necessary; (3)
						you have the legal capacity and you agree to comply with
						these Terms of Use; (4) you are not under the age of
						13;&nbsp;(5) not a minor in the jurisdiction in which
						you reside, or if a minor, you have received parental
						permission to use the Site; (6) you will not access the
						Application through automated or non-human means,
						whether through a bot, script or otherwise; (7) you will
						not use the Application for any illegal or unauthorized
						purpose; and (8) your use of the Application will not
						violate any applicable law or regulation.
					</div>
					<div class="py-2">
						If you provide any information that is untrue,
						inaccurate, not current, or incomplete, we have the
						right to suspend or terminate your account and refuse
						any and all current or future use of the Application(or
						any portion thereof).
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						USER REGISTRATION
					</div>
					<div class="py-2">
						You may be required to register with the Application. We
						reserve the right to remove, reclaim, or change a email
						you select if we determine, in our sole discretion, that
						such email is inappropriate, obscene, or otherwise
						objectionable.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						PROHIBITED ACTIVITIES
					</div>
					<div class="py-2">
						You may not access or use the Application for any
						purpose other than that for which we make the
						Application available. The Application may not be used
						in connection with any commercial endeavors except those
						that are specifically endorsed or approved by us.
					</div>
					<div class="py-2">
						As a user of the Application, you agree not to:
					</div>
					<ol>
						<li>
							Systematically retrieve data or other content from
							the Application to create or compile, directly or
							indirectly, a collection, compilation, database, or
							directory without written permission from us.
						</li>
						<li>
							Make any unauthorized use of the Application,
							including collecting usernames and/or email
							addresses of users by electronic or other means for
							the purpose of sending unsolicited email, or
							creating user accounts by automated means or under
							false pretenses.
						</li>
						<li>
							Use a buying agent or purchasing agent to make
							purchases on the Application.
						</li>
						<li>
							Circumvent, disable, or otherwise interfere with
							security-related features of the Application,
							including features that prevent or restrict the use
							or copying of any Content or enforce limitations on
							the use of the Application and/or the Content
							contained therein.
						</li>
						<li>
							Engage in unauthorized framing of or linking to the
							Application.
						</li>
						<li>
							Trick, defraud, or mislead us and other users,
							especially in any attempt to learn sensitive account
							information such as user passwords;
						</li>
						<li>
							Make improper use of our support services or submit
							false reports of abuse or misconduct.
						</li>
						<li>
							Engage in any automated use of the system, such as
							using scripts to send comments or messages, or using
							any data mining, robots, or similar data gathering
							and extraction tools.
						</li>
						<li>
							Interfere with, disrupt, or create an undue burden
							on the Application or the networks or services
							connected to the Application.
						</li>
						<li>
							Attempt to impersonate another user or person or use
							the username of another user.
						</li>
						<li>Sell or otherwise transfer your profile.</li>
						<li>
							Use any information obtained from the Application in
							order to harass, abuse, or harm another person.
						</li>
						<li>
							Use the Application as part of any effort to compete
							with us or otherwise use the Application and/or the
							Content for any revenue-generating endeavor or
							commercial enterprise.
						</li>
						<li>
							Decipher, decompile, disassemble, or reverse
							engineer any of the software comprising or in any
							way making up a part of the Application.
						</li>
						<li>
							Attempt to bypass any measures of the Application
							designed to prevent or restrict access to the
							Application, or any portion of the Application.
						</li>
						<li>
							Harass, annoy, intimidate, or threaten any of our
							employees or agents engaged in providing any portion
							of the Application to you.
						</li>
						<li>
							Delete the copyright or other proprietary rights
							notice from any Content.
						</li>
						<li>
							Copy or adapt theApplication’s software, including
							but not limited to Flash, PHP, HTML, JavaScript, or
							other code.
						</li>
						<li>
							Upload or transmit (or attempt to upload or to
							transmit) viruses, Trojan horses, or other material,
							including excessive use of capital letters and
							spamming (continuous posting of repetitive text),
							that interferes with any party’s uninterrupted use
							and enjoyment of the Application or modifies,
							impairs, disrupts, alters, or interferes with the
							use, features, functions, operation, or maintenance
							of the Application.
						</li>
						<li>
							Upload or transmit (or attempt to upload or to
							transmit) any material that acts as a passive or
							active information collection or transmission
							mechanism, including without limitation, clear
							graphics interchange formats (“gifs”), 1×1 pixels,
							web bugs, cookies, or other similar devices
							(sometimes referred to as “spyware” or “passive
							collection mechanisms” or “pcms”).
						</li>
						<li>
							Except as may be the result of standard search
							engine or Internet browser usage, use, launch,
							develop, or distribute any automated system,
							including without limitation, any spider, robot,
							cheat utility, scraper, or offline reader that
							accesses the Application, or using or launching any
							unauthorized script or other software.
						</li>
						<li>
							Disparage, tarnish, or otherwise harm, in our
							opinion, us and/or the Application.
						</li>
						<li>
							Use the Application in a manner inconsistent with
							any applicable laws or regulations.
						</li>
					</ol>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						GUIDELINES FOR REVIEWS
					</div>
					<div class="py-2">
						We may provide you areas on the Application to leave
						reviews or ratings. When posting a review, you must
						comply with the following criteria: (1) you should have
						firsthand experience with the person/entity being
						reviewed; (2) your reviews should not contain offensive
						profanity, or abusive, racist, offensive, or hate
						language; (3) your reviews should not contain
						discriminatory references based on religion, race,
						gender, national origin, age, marital status, sexual
						orientation, or disability; (4) your reviews should not
						contain references to illegal activity; (5) you should
						not be affiliated with competitors if posting negative
						reviews; (6) you should not make any conclusions as to
						the legality of conduct; (7) you may not post any false
						or misleading statements; and (8) you may not organize a
						campaign encouraging others to post reviews, whether
						positive or negative.
					</div>
					<div class="py-2">
						We may accept, reject, or remove reviews in our sole
						discretion. We have absolutely no obligation to screen
						reviews or to delete reviews, even if anyone considers
						reviews objectionable or inaccurate. Reviews are not
						endorsed by us, and do not necessarily represent our
						opinions or the views of any of our affiliates or
						partners. We do not assume liability for any review or
						for any claims, liabilities, or losses resulting from
						any review. By posting a review, you hereby grant to us
						a perpetual, non-exclusive, worldwide, royalty-free,
						fully-paid, assignable, and sublicensable right and
						license to reproduce, modify, translate, transmit by any
						means, display, perform, and/or distribute all content
						relating to reviews.<strong>&nbsp;</strong>
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						SUBMISSIONS
					</div>
					<div class="py-2">
						You acknowledge and agree that any questions, comments,
						suggestions, ideas, feedback, or other information
						regarding the Application (“Submissions”) provided by
						you to us are non-confidential and shall become our sole
						property. We shall own exclusive rights, including all
						intellectual property rights, and shall be entitled to
						the unrestricted use and dissemination of these
						Submissions for any lawful purpose, commercial or
						otherwise, without acknowledgment or compensation to
						you. You hereby waive all moral rights to any such
						Submissions, and you hereby warrant that any such
						Submissions are original with you or that you have the
						right to submit such Submissions. You agree there shall
						be no recourse against us for any alleged or actual
						infringement or misappropriation of any proprietary
						right in your Submissions.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						THIRD-PARTY WEBSITES AND CONTENT
					</div>
					<div class="py-2">
						The Application may contain (or you may be sent via the
						Application) links to other websites (“Third-Party
						Websites”) as well as articles, photographs, text,
						graphics, pictures, designs, music, sound, video,
						information, applications, software, and other content
						or items belonging to or originating from third parties
						(“Third-Party Content”). Such Third-Party Websites and
						Third-Party Content are not investigated, monitored, or
						checked for accuracy, appropriateness, or completeness
						by us, and we are not responsible for any Third-Party
						Websites accessed through the Application or any
						Third-Party Content posted on, available through, or
						installed from the Application, including the content,
						accuracy, offensiveness, opinions, reliability, privacy
						practices, or other policies of or contained in the
						Third-Party Websites or the Third-Party Content.
						Inclusion of, linking to, or permitting the use or
						installation of any Third-Party Websites or any
						Third-Party Content does not imply approval or
						endorsement thereof by us. If you decide to leave the
						Application and access the Third-Party Websites or to
						use or install any Third-Party Content, you do so at
						your own risk, and you should be aware these Terms of
						Use no longer govern. You should review the applicable
						terms and policies, including privacy and data gathering
						practices, of any website to which you navigate from the
						Application or relating to any applications you use or
						install from the Application. Any purchases you make
						through Third-Party Websites will be through other
						websites and from other companies, and we take no
						responsibility whatsoever in relation to such purchases
						which are exclusively between you and the applicable
						third party. You agree and acknowledge that we do not
						endorse the products or services offered on Third-Party
						Websites and you shall hold us harmless from any harm
						caused by your purchase of such products or services.
						Additionally, you shall hold us harmless from any losses
						sustained by you or harm caused to you relating to or
						resulting in any way from any Third-Party Content or any
						contact with Third-Party Websites.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						APP MANAGEMENT
					</div>
					<div class="py-2">
						We reserve the right, but not the obligation,
						to:&nbsp;(1) monitor the Application for violations of
						these Terms of Use; (2) take appropriate legal action
						against anyone who, in our sole discretion, violates the
						law or these Terms of Use, including without limitation,
						reporting such user to law enforcement authorities; (3)
						in our sole discretion and without limitation, refuse,
						restrict access to, limit the availability of, or
						disable (to the extent technologically feasible) any of
						your Contributions or any portion thereof; (4)
						in&nbsp;our sole discretion and without limitation,
						notice, or liability, to remove from the Application or
						otherwise disable all files and content that are
						excessive in size or are in any way burdensome to our
						systems; and (5) otherwise manage the Application in a
						manner designed to protect our rights and property and
						to facilitate the proper functioning of the Application.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						PRIVACY POLICY
					</div>
					<div class="py-2">
						We care about data privacy and security. Please review
						our
						<a href="/privacy">Privacy Policy</a>. By using the
						Application, you agree to be bound by our Privacy
						Policy, which is incorporated into these Terms of Use.
						Please be advised the Application is hosted in the
						United States. If you access the Application from the
						European Union, Asia, or any other region of the world
						with laws or other requirements governing personal data
						collection, use, or disclosure that differ from
						applicable laws in the United States, then through your
						continued use of the Application or Services, you are
						transferring your data to the United States, and you
						expressly consent to have your data transferred to and
						processed in the United States.
					</div>
					<div class="py-2">
						<strong
							>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND
							POLICY</strong
						>
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						COPYRIGHT INFRINGEMENTS
					</div>
					<div class="py-2">
						We respect the intellectual property rights of others.
						If you believe that any material available on or through
						the Application infringes upon any copyright you own or
						control, please immediately notify us using the contact
						information provided below (a “Notification”). A copy of
						your Notification will be sent to the person who posted
						or stored the material addressed in the Notification.
						Please be advised that pursuant to federal law you may
						be held liable for damages if you make material
						misrepresentations in a Notification. Thus, if you are
						not sure that material located on or linked to by the
						Application infringes your copyright, you should
						consider first contacting an attorney.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						TERM AND TERMINATION
					</div>
					<div class="py-2">
						These Terms of Use shall remain in full force and effect
						while you use the Application. WITHOUT LIMITING ANY
						OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE
						RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
						LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
						BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
						REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
						FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
						CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW
						OR REGULATION. WE MAY TERMINATE YOUR USE OR
						PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
						CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
						WITHOUT WARNING, IN OUR SOLE DISCRETION.
					</div>
					<div class="py-2">
						If we terminate or suspend your account for any reason,
						you are prohibited from registering and creating a new
						account under your name, a fake or borrowed name, or the
						name of any third party, even if you may be acting on
						behalf of the third party. In addition to terminating or
						suspending your account, we reserve the right to take
						appropriate legal action, including without limitation
						pursuing civil, criminal, and injunctive redress.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						MODIFICATIONS AND INTERRUPTIONS
					</div>
					<div class="py-2">
						We reserve the right to change, modify, or remove the
						contents of the Application at any time or for any
						reason at our sole discretion without notice. However,
						we have no obligation to update any information on our
						Application. We also reserve the right to modify or
						discontinue all or part of the Application without
						notice at any time. We will not be liable to you or any
						third party for any modification, price change,
						suspension, or discontinuance of the Application.
					</div>
					<div class="py-2">
						We cannot guarantee the Application will be available at
						all times. We may experience hardware, software, or
						other problems or need to perform maintenance related to
						the Application, resulting in interruptions, delays, or
						errors. We reserve the right to change, revise, update,
						suspend, discontinue, or otherwise modify the
						Application at any time or for any reason without notice
						to you. You agree that we have no liability whatsoever
						for any loss, damage, or inconvenience caused by your
						inability to access or use the Application during any
						downtime or discontinuance of the Application. Nothing
						in these Terms of Use will be construed to obligate us
						to maintain and support the Application or to supply any
						corrections, updates, or releases in connection
						therewith.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						GOVERNING LAW
					</div>
					<div class="py-2">
						These Terms of Use and your use of the Application are
						governed by and construed in accordance with the laws of
						the State of West Bengal applicable to agreements made
						and to be entirely performed within the State of West
						Bengal, India, without regard to its conflict of law
						principles.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						DISPUTE RESOLUTION
					</div>
					<div class="py-2">
						To expedite resolution and control the cost of any
						dispute, controversy, or claim related to these Terms of
						Use (each a “Dispute” and collectively, the “Disputes”)
						brought by either you or us (individually, a “Party” and
						collectively, the “Parties”), the Parties agree to first
						attempt to negotiate any Dispute (except those Disputes
						expressly provided below) informally for at least 30
						days before initiating arbitration. Such informal
						negotiations commence upon written notice from one Party
						to the other Party.
					</div>
					<div class="text-xl text-black py-6">
						Binding Arbitration
					</div>
					<div class="py-2">
						If the Parties are unable to resolve a Dispute through
						informal negotiations, the Dispute (except those
						Disputes expressly excluded below) will be finally and
						exclusively resolved by binding arbitration. YOU
						UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE
						THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
						arbitration shall be commenced and conducted under the
						Commercial Arbitration Rules of the American Arbitration
						Association (“AAA”) and, where appropriate, the AAA’s
						Supplementary Procedures for Consumer Related Disputes
						(“AAA Consumer Rules”), both of which are available at
						the AAA website www.adr.org. Your arbitration fees and
						your share of arbitrator compensation shall be governed
						by the AAA Consumer Rules and, where appropriate,
						limited by the AAA Consumer Rules. The arbitration may
						be conducted in person, through the submission of
						documents, by phone, or online. The arbitrator will make
						a decision in writing, but need not provide a statement
						of reasons unless requested by either Party. The
						arbitrator must follow applicable law, and any award may
						be challenged if the arbitrator fails to do so. Except
						where otherwise required by the applicable AAA rules or
						applicable law, the arbitration will take place in West
						Bengal, India. Except as otherwise provided herein, the
						Parties may litigate in court to compel arbitration,
						stay proceedings pending arbitration, or to confirm,
						modify, vacate, or enter judgment on the award entered
						by the arbitrator.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CORRECTIONS
					</div>
					<div class="py-2">
						There may be information on the Application that
						contains typographical errors, inaccuracies, or
						omissions that may relate to the Application, including
						descriptions, pricing, availability, and various other
						information. We reserve the right to correct any errors,
						inaccuracies, or omissions and to change or update the
						information on the Application at any time, without
						prior notice.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						DISCLAIMER
					</div>
					<div class="py-2">
						THE APPLICATION IS PROVIDED ON AN AS-IS AND AS-AVAILABLE
						BASIS. YOU AGREE THAT YOUR USE OF THE APPLICATION
						SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
						EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
						EXPRESS OR IMPLIED, IN CONNECTION WITH THE APPLICATION
						AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
						IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
						PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
						WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
						COMPLETENESS OF THE APPLICATION’S CONTENT OR THE CONTENT
						OF ANY WEBSITES LINKED TO THIS APPLICATION AND WE WILL
						ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
						ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
						MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
						ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
						USE OF THE APPLICATION, (3) ANY UNAUTHORIZED ACCESS TO
						OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
						INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
						(4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
						FROM THE APPLICATION, (5) ANY BUGS, VIRUSES, TROJAN
						HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
						THROUGH THE APPLICATION BY ANY THIRD PARTY, AND/OR (6)
						ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
						FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
						OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
						OTHERWISE MADE AVAILABLE VIA THE APPLICATION. WE DO NOT
						WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
						FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
						THIRD PARTY THROUGH THE APPLICATION, ANY HYPERLINKED
						WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED
						IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
						PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
						TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
						PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
						OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
						SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
						APPROPRIATE.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						INDEMNIFICATION
					</div>
					<div class="py-2">
						You agree to defend, indemnify, and hold us harmless,
						including our subsidiaries, affiliates, and all of our
						respective officers, agents, partners, and employees,
						from and against any loss, damage, liability, claim, or
						demand, including reasonable attorneys’ fees and
						expenses, made by any third party due to or arising out
						of: (1) your Contributions; (2) use of the Site; (3)
						breach of these Terms of Use; (4) any breach of your
						representations and warranties set forth in these Terms
						of Use; (5) your violation of the rights of a third
						party, including but not limited to intellectual
						property rights; or (6) any overt harmful act toward any
						other user of the Site with whom you connected via the
						Site. Notwithstanding the foregoing, we reserve the
						right, at your expense, to assume the exclusive defense
						and control of any matter for which you are required to
						indemnify us, and you agree to cooperate, at your
						expense, with our defense of such claims. We will use
						reasonable efforts to notify you of any such claim,
						action, or proceeding which is subject to this
						indemnification upon becoming aware of it.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						USER DATA
					</div>
					<div class="py-2">
						We will maintain certain data that you transmit to the
						Site for the purpose of managing the Site, as well as
						data relating to your use of the Site. Although we
						perform regular routine backups of data, you are solely
						responsible for all data that you transmit or that
						relates to any activity you have undertaken using the
						Site. You agree that we shall have no liability to you
						for any loss or corruption of any such data, and you
						hereby waive any right of action against us arising from
						any such loss or corruption of such data.
					</div>
					<div class="py-2">
						<strong
							>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
							SIGNATURES</strong
						>
					</div>
					<div class="py-2">
						Visiting the Site, sending us emails, and completing
						online forms constitute electronic communications. You
						consent to receive electronic communications, and you
						agree that all agreements, notices, disclosures, and
						other communications we provide to you electronically,
						via email and on the Site, satisfy any legal requirement
						that such communication be in writing. YOU HEREBY AGREE
						TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
						AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
						NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED
						OR COMPLETED BY US OR VIA THE SITE. You hereby waive any
						rights or requirements under any statutes, regulations,
						rules, ordinances, or other laws in any jurisdiction
						which require an original signature or delivery or
						retention of non-electronic records, or to payments or
						the granting of credits by any means other than
						electronic means.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CALIFORNIA USERS AND RESIDENTS
					</div>
					<div class="py-2">
						If any complaint with us is not satisfactorily resolved,
						you can contact the Complaint Assistance Unit of the
						Division of Consumer Services of the California
						Department of Consumer Affairs in writing at 1625 North
						Market Blvd., Suite N 112, Sacramento, California 95834
						or by telephone at (800) 952-5210 or (916) 445-1254.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						MISCELLANEOUS
					</div>
					<div class="py-2">
						These Terms of Use and any policies or operating rules
						posted by us on the Application constitute the entire
						agreement and understanding between you and us. Our
						failure to exercise or enforce any right or provision of
						these Terms of Use shall not operate as a waiver of such
						right or provision. These Terms of Use operate to the
						fullest extent permissible by law. We may assign any or
						all of our rights and obligations to others at any time.
						We shall not be responsible or liable for any loss,
						damage, delay, or failure to act caused by any cause
						beyond our reasonable control. If any provision or part
						of a provision of these Terms of Use is determined to be
						unlawful, void, or unenforceable, that provision or part
						of the provision is deemed severable from these Terms of
						Use and does not affect the validity and enforceability
						of any remaining provisions. There is no joint venture,
						partnership, employment or agency relationship created
						between you and us as a result of these Terms of Use or
						use of the Site. You agree that these Terms of Use will
						not be construed against us by virtue of having drafted
						them. You hereby waive any and all defenses you may have
						based on the electronic form of these Terms of Use and
						the lack of signing by the parties hereto to execute
						these Terms of Use.
					</div>
					<div class="text-xl md:text-3xl text-black font-bold py-6">
						CONTACT US
					</div>
					<div class="py-2">
						In order to resolve a complaint regarding the
						Application or to receive further information regarding
						use of the Application, please contact us at:
					</div>
					<div class="py-2">
						<span>QuantumParadox Studio Private Limited</span>
					</div>
					<div class="py-2">
						<span
							>Corporate Identity Number (CIN)
							U72900WB2021PTC243998</span
						>
					</div>
					<div class="py-2">
						<span>photon@quantumparadox.studio</span>
					</div>
				</div>
			</div>
		</ATDContainer>
	</div>
</template>
<script>
import ATDContainer from "@/layouts/ATDContainer";

export default {
	components: { ATDContainer }
};
</script>
